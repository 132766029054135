<template>
    <div>
        <Order ref="orderModal"/>
        <Authenticate ref="authenticate"/>

        <div>
            <b-col lg="6" class="ml-auto my-1">
                <b-form-group label="Filter" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="filterInput" class="mb-0">
                    <b-input-group size="sm">
                        <b-form-input v-model="filter" type="search" id="filterInput" placeholder="Type to Search"></b-form-input>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-table id="orderTable" striped small hover responsive sort-icon-left :busy="isBusy" :items="items" :fields="fields" :tbody-tr-class="rowClass" ref="orderTable" @row-clicked="openOrder">
                <template v-slot:table-busy>
                    <div class="text-center my-2">
                        <b-spinner size="sm" class="align-middle mr-2"></b-spinner>
                        <strong>Loading orders...</strong>
                    </div>
                </template>
                <template v-slot:cell(show_details)="row">
                    <b-button size="sm" @click="row.toggleDetails" v-if="row.detailsShowing"><b-icon-chevron-up/></b-button>
                    <b-button size="sm" @click="row.toggleDetails" v-if="!row.detailsShowing"><b-icon-chevron-down/></b-button>
                </template>
                <template v-slot:row-details="row">
                    <b-container fluid class="mb-2">
                        <b-row v-for="prd in row.item.products" :key="prd.product_id" :class="{ 'opacity-50': prd.product_type != 'simple' }" class="text-muted text-small">
                            <b-col cols="3">{{ prd.name }}</b-col>
                            <b-col cols="3">{{ prd.sku }}</b-col>
                            <b-col cols="1">{{ prd.price }}</b-col>
                            <b-col cols="1">Ordered {{ prd.qty_ordered }}</b-col>
                            <b-col cols="1">Backordered {{ prd.qty_backordered }}</b-col>
                            <b-col cols="1">Refunded {{ prd.qty_refunded }}</b-col>
                            <b-col cols="1">Invoiced {{ prd.qty_invoiced }}</b-col>
                            <b-col cols="1">Shipped {{ prd.qty_shipped }}</b-col>
                        </b-row>
                    </b-container>
                </template>
            </b-table>
        </div>
    </div>
</template>

<script>
    import Order from './Order.vue'
    import Authenticate from './Authenticate.vue'
    import bus from '../event-bus.js';
    import magento from '../magento.js';

    export default {
        name: 'Orders',
        props: {
        },
        components: {
            Authenticate,
            Order
        },

        mounted() {
            var that = this;
            bus.$on('magento:authenticated', function() {
                magento.getOrders();
            });
            bus.$on('magento:orders-loaded', function(data) {
                that.refreshTable(data);
            });

            bus.$on('magento:progress', function() {
                that.isBusy = true;
            });
            bus.$on('magento:done', function() {
                that.isBusy = false;
            });

            if (magento.isAuthenticated()) {
                magento.getOrders();
            } else {
                this.$refs.authenticate.authenticate();
            }
        },

        methods: {
            refreshTable(data) {
                this.items = data.map(item => ({
                    _showDetails: false,
                    order_id: item.increment_id,
                    entity_id: item.entity_id,
                    customer_firstname: item.customer_firstname,
                    customer_lastname: item.customer_lastname,
                    customer_name: (item.customer_firstname ? item.customer_firstname : item.billing_address.firstname) + ' ' + (item.customer_lastname ? item.customer_lastname : item.billing_address.lastname),
                    customer_email: item.customer_email,
                    email: item.customer_email ? item.customer_email : item.extension_attributes.shipping_assignments[0].shipping.address.email,
                    country_code: item.billing_address.country_id ? item.billing_address.country_id : item.extension_attributes.shipping_assignments[0].shipping.address.country_id,
                    status: item.status,
                    payment_method: this.mapPayment(item.payment.method, item.payment.additional_information[0]),
                    payment_amount: item.base_grand_total,
                    payment_value_rounded: Math.ceil(item.base_grand_total / 10) * 10,
                    currency: item.base_currency_code,
                    total_due: item.total_due,
                    total_invoiced: item.total_invoiced,
                    total_paid: item.total_paid,
                    total_qty_ordered: item.total_qty_ordered,

                    created_at: item.created_at,
                    items_count: item.items.filter(function(item){return item.product_type==='simple'}).length,
                    weight: item.weight,

                    shipping_amount: item.shipping_amount,
                    shipping_description: item.shipping_description,

                    shipping_address: item.extension_attributes.shipping_assignments[0].shipping.address,
                    billing_address: item.billing_address,

                    amazon_order_id: item.payment.additional_information[2],
                    order_id_str: (item.payment.additional_information[0] === 'amazon' || item.payment.additional_information[0] === 'ebay') ? item.payment.additional_information[2] : item.increment_id,

                    status_histories: item.status_histories,

                    products: item.items.map(product => ({
                        product_id: product.product_id,
                        product_type: product.product_type,
                        item_id: product.item_id,
                        name: product.name,
                        sku: product.sku,
                        price: product.price,
                        weight: product.weight,
                        quantity: (product.qty_ordered - product.qty_refunded) - product.qty_shipped,
                        qty_backordered: product.qty_backordered,
                        qty_canceled: product.qty_canceled,
                        qty_invoiced: product.qty_invoiced,
                        qty_ordered: product.qty_ordered,
                        qty_refunded: product.qty_refunded,
                        qty_shipped: product.qty_shipped
                    }))
                }));
                //this.$refs.orderTable.refresh();
            },
            mapPayment(value, extra) {
                switch(value) {
                case 'paypal_express':
                    return 'PayPal'
                case 'banktransfer':
                    return 'Bank Transfer'
                case 'stripe_payments':
                    return 'Stripe'
                case 'm2epropayment':
                    switch(extra) {
                    case 'ebay':
                        return 'eBay'
                    case 'amazon':
                        return 'Amazon'
                    }
                    return 'M2EPro'
                case 'cashondelivery':
                    return 'Cash on Delivery';
                default:
                    return value
                }
            },
            openOrder(record, index) {
                this.$refs.orderModal.openOrder(this.items[index]);
            },
            rowClass(item, type) {
                if (!item || type !== 'row') return
                return (item.status === 'complete' || item.status === 'canceled' || item.status === 'closed') ? 'text-muted' : null
            }
        },

        data() {
            return {
                isBusy: false,
                items: [],
                fields: [
                    { key: 'order_id_str', label: '#', tdClass: 'text-small', sortable: true, stickyColumn: false },
                    { key: 'customer_name', label: 'Customer', tdClass: 'text-bold', sortable: true },
                    { key: 'country_code', label: 'Country', sortable: true },
                    { key: 'status', label: 'Status', tdClass: 'text-bold', sortable: true, formatter: value => { return value.toUpperCase() } },
                    { key: 'created_at', label: 'Created', sortable: true, formatter: value => { return value.substring(0, 10) } },
                    { key: 'payment_method', label: 'Payment', sortable: true },
                    { key: 'payment_amount', label: 'Amount', tdClass: 'text-right', sortable: true },
                    { key: 'total_paid', label: 'Paid', tdClass: 'text-right', sortable: true, formatter: value => { return value ? value : '0.0' } },
                    { key: 'shipping_amount', label: 'Postage', tdClass: 'text-right', sortable: true },
                    { key: 'currency', label: 'Currency', sortable: true },
                    { key: 'shipping_description', label: 'Shipping', sortable: true },
                    { key: 'weight', label: 'Weight', tdClass: 'text-right', sortable: true, formatter: value => { return value + ' kg' } },
                    { key: 'items_count', label: 'Items', tdClass: 'text-right', sortable: true },
                    { key: 'show_details', label: 'Detail' }
                ],
                filter: null,
                filterOn: []
            }
        }

    }
</script>

<style scoped>
    @import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
    .table {
        font-family: 'Open Sans', sans-serif;
    }
    .text-bold {
        font-weight: 800;
    }
    .opacity-50 {
        opacity: 0.5;
    }
</style>
