<template>
    <div>
        Products
    </div>
</template>

<script>
    export default {
        name: 'Products',
        props: {
        }
    }
</script>

<style scoped>
</style>
