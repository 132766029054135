<template>
<div>
    <b-modal id="labelDialog" content-class="shadow" size="xl" title="Shipping label">
        <b-container fluid v-if="order">
            <b-row>

                <b-col>
                    <b-row>
                        <b-col class="text-muted"><strong>Order number:</strong></b-col>
                        <b-col cols="8">
                            <b-form-input v-model="order.order_id" class="mb-1"/>
                            <b-form-checkbox v-model="marketplaceOrder" class="mb-1 text-seconary">Marketplace order {{ order.amazon_order_id }}</b-form-checkbox>
                        </b-col>
                    </b-row>
                    <b-row class="mb-2">
                        <b-col class="text-muted"><strong>Carrier:</strong></b-col>
                        <b-col cols="8">
                            <b-dropdown split boundary="viewport" variant="outline-secondary" v-bind:text="selectedCarrier.name">
                                <b-dropdown-item class="dropdown-item-info" v-for="carrier in carriers" v-bind:key="carrier.id" @click="selectedCarrier=carrier">{{ carrier.name }}</b-dropdown-item>
                            </b-dropdown>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col class="text-muted"><strong>First name:</strong></b-col>
                        <b-col cols="8"><b-form-input required v-model="order.shipping_address.firstname" class="mb-1"/></b-col>
                    </b-row>
                    <b-row>
                        <b-col class="text-muted"><strong>Middle name:</strong></b-col>
                        <b-col cols="8"><b-form-input required v-model="order.shipping_address.middlename" class="mb-1"/></b-col>
                    </b-row>
                    <b-row>
                        <b-col class="text-muted"><strong>Last name:</strong></b-col>
                        <b-col cols="8"><b-form-input required v-model="order.shipping_address.lastname" class="mb-1"/></b-col>
                    </b-row>
                    <b-row>
                        <b-col class="text-muted"><strong>E-mail:</strong></b-col>
                        <b-col cols="8"><b-form-input required v-model="order.email" class="mb-1"/></b-col>
                    </b-row>
                    <b-row class="mb-1">
                        <b-col class="text-muted"><strong>Phone number:</strong></b-col>
                        <b-col cols="8">
                            <VuePhoneNumberInput required v-model="order.shipping_address.telephone" :default-country-code="order.shipping_address.country_id" v-on:update="formatPhone" valid-color="#6f6f6f"/>
                            <small class="text-muted ml-1 mt-0">{{ order.shipping_address.formattedNumber }}</small>
                        </b-col>
                    </b-row>
                    <b-row class="mb-1">
                        <b-col class="text-muted"><strong>Company:</strong></b-col>
                        <b-col cols="8"><b-form-input v-model="order.shipping_address.company"/></b-col>
                    </b-row>
                    <b-row class="mb-1">
                        <b-col class="text-muted"><strong>Street:</strong></b-col>
                        <b-col cols="8">
                            <b-form-input required v-model="order.shipping_address.street[0]"/>
                            <small class="text-muted ml-1 mt-0">{{ order.shipping_address.street[1] }}</small>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col class="text-muted"><strong>House number:</strong></b-col>
                        <b-col cols="8"><b-form-input required v-model="order.shipping_address.housenumber" class="mb-1 w-50"/></b-col>
                    </b-row>
                    <b-row class="mb-1">
                        <b-col class="text-muted"><strong>City:</strong></b-col>
                        <b-col cols="8">
                            <b-form-input required v-model="order.shipping_address.city" class="mb-0"/>
                            <small class="text-muted ml-1 mt-0">{{ order.shipping_address.region }}, {{ order.shipping_address.region_code }}</small>
                        </b-col>
                    </b-row>
                    <b-row class="mb-1">
                        <b-col class="text-muted"><strong>Postcode:</strong></b-col>
                        <b-col cols="8">
                            <b-input-group :append="order.shipping_address.country_id" class="w-75">
                                <b-form-input required v-model="order.shipping_address.postcode"/>
                            </b-input-group>
                        </b-col>
                    </b-row>
                    <b-row class="mb-1">
                        <b-col class="text-muted"><strong>Weight:</strong></b-col>
                        <b-col cols="8">
                            <b-input-group :append="shippingLimit()" class="w-50">
                                <b-form-input v-model="order.shipping_weight"/>
                            </b-input-group>
                        </b-col>
                    </b-row>
                    <b-row class="mb-2">
                        <b-col class="text-muted"><strong>Value:</strong></b-col>
                        <b-col cols="8">
                            <b-input-group :append="order.currency" class="w-50">
                                <b-form-input v-model="order.payment_value_rounded"/>
                            </b-input-group>
                            <small class="ml-1 text-muted">{{ order.payment_amount }} {{ order.currency }}</small>
                        </b-col>
                    </b-row>

                    <b-row class="mb-1">
                        <b-col>
                            <b-input-group append="Packet ID">
                                <b-form-input readonly v-model="packetId" class="white-background"/>
                            </b-input-group>
                        </b-col>
                    </b-row>
                    <b-row class="mb-1">
                        <b-col>
                            <b-input-group append="Zasielkovna.sk">
                                <b-form-input readonly v-model="barcode" class="white-background"/>
                            </b-input-group>
                        </b-col>
                    </b-row>
                    <b-row class="mb-1">
                        <b-col>
                            <b-input-group :append="selectedCarrier.name">
                                <b-form-input v-model="carrierBarcode" class="white-background"/>
                            </b-input-group>
                        </b-col>
                    </b-row>
                </b-col>

                <b-col>
                    <b-row class="mb-1">
                        <b-col align-v="center" align="center"><img :src="carrierImage" fluid class="w-75"/></b-col>
                    </b-row>
                    <b-row>
                        <b-col><code class="text-muted">{{ error }}</code></b-col>
                    </b-row>

                </b-col>

            </b-row>
        </b-container>
        <b-container fluid>
            <h4 class="mt-3 mb-3 text-muted">Items to ship</h4>
            <b-row align-v="center" v-for="product in order.products" :key="product.item_id" :class="{'header':product.product_type!='simple'}" class="mb-1">
                <b-col cols="1"><b-form-input type="number" size="sm" v-if="product.product_type=='simple'" v-model="product.quantity"/></b-col>
                <b-col cols="3" class="text-muted">{{ product.sku }}</b-col>
                <b-col cols="1"></b-col>
                <b-col cols="4">{{ product.name }}</b-col>
                <b-col cols="1" align-self="end">{{ product.weight }} kg</b-col>
                <b-col cols="2" align-self="end">{{ product.price }} {{ order.currency }}</b-col>
            </b-row>
        </b-container>

        <template v-slot:modal-footer>
        <div class="w-100">
          <b-button size="md" variant="secondary" @click="$bvModal.show('labelDialogCustoms')" class="mr-2">Customs</b-button>
          <b-button size="md" variant="secondary" @click="registerLabel" class="mr-2">Create label</b-button>
          <b-button size="md" variant="outline-secondary" class="mr-2">Print label</b-button>
          <b-button size="md" variant="outline-secondary" @click="$bvModal.hide('labelDialog')" class="float-right">Close</b-button>
          <b-button size="md" variant="info" @click="confirmShipment" class="float-right mr-2">Ship {{ shippingItemsTotal() }} items</b-button>
          <b-form-checkbox v-model="customsDeclaration" class="text-seconary float-right mt-2 mr-2">Customs declaration</b-form-checkbox>
        </div>
      </template>
    </b-modal>

    <b-modal id="labelDialogCustoms" centered content-class="shadow" size="lg" title="Custom declaration">
        <b-container fluid v-for="item in order.productsSimple" :key="item.item_id" class="mb-2 py-3 declaration-item">
            <b-row>
                <b-col>
                    <b-row class="mb-1">
                        <b-col class="text-muted"><strong>HS code</strong></b-col>
                        <b-col cols="8"><b-form-input v-model="item.hs_code"/></b-col>
                    </b-row>
                    <b-row class="mb-1">
                        <b-col class="text-muted"><strong>Name</strong></b-col>
                        <b-col cols="8"><b-form-input v-model="item.name"/></b-col>
                    </b-row>
                    <b-row class="mb-1">
                        <b-col class="text-muted"><strong>Value in EUR</strong></b-col>
                        <b-col cols="8"><b-form-input v-model="item.price"/></b-col>
                    </b-row>
                    <b-row class="mb-1">
                        <b-col class="text-muted"><strong>Country</strong></b-col>
                        <b-col cols="8"><b-form-input v-model="item.country"/></b-col>
                    </b-row>
                    <b-row class="mb-1">
                        <b-col class="text-muted"><strong>Invoice</strong></b-col>
                        <b-col cols="8"><b-form-input v-model="order.order_id"/></b-col>
                    </b-row>
                    <b-row class="mb-1">
                        <b-col class="text-muted"><strong>Invoice date</strong></b-col>
                        <b-col cols="8"><b-form-input v-model="order.created_at"/></b-col>
                    </b-row>
                    <b-row class="mb-1">
                        <b-col class="text-muted"><strong>Weight</strong></b-col>
                        <b-col cols="8"><b-form-input v-model="item.weight"/></b-col>
                    </b-row>
                </b-col>
                <b-col>
                    <b-row class="mb-1">
                        <b-col class="text-muted"><strong>EAN</strong></b-col>
                        <b-col cols="8"><b-form-input v-model="item.ean"/></b-col>
                    </b-row>
                    <b-row class="mb-1">
                        <b-col class="text-muted"><strong>Name dest.</strong></b-col>
                        <b-col cols="8"><b-form-input v-model="item.name"/></b-col>
                    </b-row>
                    <b-row class="mb-1">
                        <b-col class="text-muted"><strong>Value dest.</strong></b-col>
                        <b-col cols="8"><b-form-input v-model="item.price"/></b-col>
                    </b-row>
                    <b-row class="mb-1">
                        <b-col class="text-muted"><strong>Currency</strong></b-col>
                        <b-col cols="8"><b-form-input v-model="order.currency"/></b-col>
                    </b-row>
                    <b-row class="mb-1">
                        <b-col class="text-muted"><strong>Units count</strong></b-col>
                        <b-col cols="8"><b-form-input v-model="item.qty_invoiced"/></b-col>
                    </b-row>
                    <b-row class="mt-3">
                        <b-col cols="12">
                            <b-form-checkbox v-model="item.isFood" class="text-seconary">Item is food or a book</b-form-checkbox>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12">
                            <b-form-checkbox v-model="item.isCompound" class="text-seconary">Item is volatile organic compound</b-form-checkbox>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </b-container>
    </b-modal>

</div>
</template>

<script>
import magento from '../magento.js';
import zasielkovna from '../zasielkovna.js';
import axios from 'axios';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

export default {
    name: 'ShippingLabel',
    props: {
    },
    components: {
        VuePhoneNumberInput
    },

    mounted() {
    },

    methods: {
        openLabelFor(order) {
            this.order = order
            if (order.amazon_order_id) {
                this.marketplaceOrder = true;
            }
            var carriers = zasielkovna.getCarriers();
            this.carriers = carriers.filter(function(item) {
                return item.country.toUpperCase() === order.shipping_address.country_id.toUpperCase()
            });
            this.selectedCarrier = this.carriers.length > 0 ? this.carriers[0] : {};
            this.customsDeclaration = this.selectedCarrier.customsDeclarations;
            this.order.shipping_weight = this.shippingWeight()
            this.$bvModal.show('labelDialog');

            // Retrieve EAN codes
            this.order.products.forEach((product) => {
                if (product.product_type === 'simple') {
                    magento.getProductBySKU(product.sku, function success(data) {
                        if (data != null) {
                            var eancodes = data.custom_attributes.filter(function(item) {
                                return item.attribute_code === 'ean';
                            });
                            product.ean = eancodes[0].value;

                            var hscodes = data.custom_attributes.filter(function(item) {
                                return item.attribute_code === 'hs_code';
                            });
                            product.hs_code = hscodes[0] ? hscodes[0].value : '39169090';

                            var countries = data.custom_attributes.filter(function(item) {
                                return item.attribute_code === 'country_of_manufacture';
                            });
                            product.country = countries[0].value;
                        }
                    });
                }
            });

        },
        formatPhone(event) {
            this.order.shipping_address.formattedNumber = event.formattedNumber;
        },
        getOrderNumber() {
            return this.marketplaceOrder ? this.order.amazon_order_id : this.order.order_id
        },

        shippingItemsTotal() {
            var total = 0;
            for (var p = 0; p < this.order.products.length; p++) {
                if (this.order.products[p].product_type === 'simple') {
                    total += parseInt(this.order.products[p].quantity)
                }
            }
            return total
        },
        shippingWeight() {
            var total = 0;
            for (var p = 0; p < this.order.products.length; p++) {
                if (this.order.products[p].product_type === 'simple') {
                    total += this.order.products[p].quantity * this.order.products[p].weight;
                }
            }
            return total
        },
        shippingLimit() {
            return `Max. ${this.selectedCarrier.maxWeight}kg`
        },

        registerLabel() {

            this.jcd = '<items>';
            this.order.productsSimple.forEach((item) => {
                this.jcd = this.jcd + `
<item>
  <attributes>
    <attribute>
        <key>customsCode</key>
        <value>${item.hs_code}</value>
    </attribute>
    <attribute>
        <key>value</key>
        <value>${item.price}</value>
    </attribute>
    <attribute>
        <key>productNameEn</key>
        <value>${item.name}</value>
    </attribute>
    <attribute>
        <key>unitsCount</key>
        <value>${item.quantity}</value>
    </attribute>
    <attribute>
        <key>currency</key>
        <value>${this.order.currency}</value>
    </attribute>
    <attribute>
        <key>weight</key>
        <value>${item.weight}</value>
    </attribute>
    <attribute>
        <key>countryOfOrigin</key>
        <value>${item.country}</value>
    </attribute>
  </attributes>
</item>`;
            });
            this.jcd = this.jcd + '</items>';

            console.log('JCD', this.jcd);

            // First and/or middle names combined
            var names = this.order.shipping_address.middlename ? this.order.shipping_address.firstname + ' ' + this.order.shipping_address.middlename : this.order.shipping_address.firstname;

            this.xml = `
<createPacket>
<apiPassword>${localStorage.zasielkovnaPassword}</apiPassword>
<packetAttributes>
<number>${this.marketplaceOrder ? this.order.amazon_order_id : this.order.order_id}</number>
<weight>${this.shippingWeight()}</weight>
<value>${this.order.payment_value_rounded}</value>
<currency>${this.order.currency}</currency>
<name>${names}</name>
<surname>${this.order.shipping_address.lastname}</surname>
<phone>${this.order.shipping_address.formattedNumber}</phone>
<email>${this.order.email}</email>
<company>${this.order.shipping_address.company ? this.order.shipping_address.company : ''}</company>
<street>${this.order.shipping_address.street[0]}</street>
<houseNumber>${this.order.shipping_address.housenumber}</houseNumber>
<addressId>${this.selectedCarrier.id}</addressId>
<eshop>threed.store</eshop>
<city>${this.order.shipping_address.city}</city>
<zip>${this.order.shipping_address.postcode.toUpperCase()}</zip>`;
            if (this.customsDeclaration === true) {
                this.xml = this.xml + this.jcd;
            }
            this.xml = this.xml + `</packetAttributes></createPacket>`;

            console.log('XML', this.xml);

            this.packetId = '';
            this.barcode = '';
            this.carrierBarcode = '';
            this.carrierImage = null;
            this.error = '';
            this.error = this.xml;

            axios.post('https://www.zasilkovna.cz/api/rest', this.xml, {
                headers: {
                    'Content-Type': 'text/xml'
                }
            }).then(response => {
                console.log('Response', response.data);
                var json = zasielkovna.parseXML(response.data);

                var status = json.response.status['#text'];
                if (status === 'ok') {
                    this.packetId = json.response.result.id['#text'];
                    this.barcode = json.response.result.barcode['#text'];

                    // Retrieve Carrier barcode

                    var request = `<packetCourierNumber><apiPassword>${localStorage.zasielkovnaPassword}</apiPassword><packetId>${this.packetId}</packetId></packetCourierNumber>`;
                    axios.post('https://www.zasilkovna.cz/api/rest', request, {
                        headers: {
                            'Content-Type': 'text/xml'
                        }
                    }).then(response => {
                        console.log('Response', response.data);
                        var json = zasielkovna.parseXML(response.data);

                        console.log(json);

                        var status = json.response.status['#text'];
                        if (status === 'ok') {
                            this.carrierBarcode = json.response.result['#text'];
                            console.log('Carrier barcode', this.carrierBarcode);

                            // Download carrier barcode for printing

                            console.log('Loading barcode image...');
                            var request = `<packetCourierLabelPng><apiPassword>${localStorage.zasielkovnaPassword}</apiPassword><packetId>${this.packetId}</packetId><courierNumber>${this.carrierBarcode}</courierNumber></packetCourierLabelPng>`;
                            axios.post('https://www.zasilkovna.cz/api/rest', request, {
                                headers: {
                                    'Content-Type': 'text/xml'
                                }
                            }).then(response => {
                                console.log('Response', response);
                                var json = zasielkovna.parseXML(response.data);

                                var status = json.response.status['#text'];
                                if (status === 'ok') {
                                    console.log('Image loaded');
                                    this.carrierImage = "data:image/png;base64," + json.response.result['#text'];
                                } else {
                                    this.error = json;
                                }

                            }).catch(e => {
                                console.log('Error carrier label image', e);
                            });

                        } else {
                            this.error = json;
                            console.log('Courier number failed', json);
                        }

                    }).catch(e => {
                        console.log('Error courier barcode', e);
                    });

                } else {
                    this.error = json;
                    console.log('JSON', json);
                    this.$bvToast.toast(`Failed to create label`, {
                        title: 'Error',
                        autoHideDelay: 5000,
                    });
                }

            }).catch(e => {
                console.log('Error creating label', e);
                this.$bvToast.toast(`Failed to create label`, {
                    title: 'Error',
                    autoHideDelay: 5000,
                });
            });
        },

        confirmShipment() {
            var payload = {
                notify: true
            }

            payload.items = [];
            for (var p = 0; p < this.order.products.length; p++) {
                var product = this.order.products[p];
                if (product.product_type === 'simple') {
                    if (product.quantity > 0) {
                        payload.items.push({
                            qty: product.quantity,
                            order_item_id: product.item_id //product.parent_item ? product.parent_item.item_id : product.item_id
                        });
                    }
                }
            }

            payload.tracks = [
                {
                    track_number: this.barcode,
                    title: 'Packeta',
                    carrier_code: 'packeta'
            }];
            if (this.carrierBarcode) {
                payload.tracks.push({
                    track_number: this.carrierBarcode,
                    title: this.selectedCarrier.name,
                    carrier_code: `${this.selectedCarrier.id}`
                });
            }

            console.log(payload);
            magento.shipOrder(this.order.entity_id, payload);
        }
    },

    data() {
        return {
            order: {
            },
            carriers: [],
            selectedCarrier: {},
            marketplaceOrder: false,
            customsDeclaration: false,
            xml: '',
            packetId: '',
            barcode: '',
            carrierBarcode: '',
            carrierImage: null,
            error: ''
        }
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Libre+Barcode+128&family=Libre+Barcode+128+Text&display=swap');
.header {
    color: #7f7f7f;
    font-size: 8pt;
    font-weight: bold;
}
.barcode {
    font-family: 'Libre Barcode 128 Text', cursive;
    font-size: 34px;
}
.white-background {
    background-color: white;
}
.dropdown-menu {
    max-height: 300px;
    overflow-y: auto;
}
.declaration-item {
    background-color: #FAFAFA;
    border-radius: 5px;
}
</style>
