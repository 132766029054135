import Vue from 'vue'
import VueRouter from 'vue-router'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import App from './App.vue'
import Orders from './components/Orders.vue'
import Products from './components/Products.vue'
import Financial from './components/Financial.vue'

Vue.config.productionTip = false

Vue.use(VueRouter)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

const routes = [
    { path: '/', redirect: '/orders' },
    { path: '/orders', component: Orders },
    { path: '/products', component: Products },
    { path: '/financial', component: Financial }];

const router = new VueRouter({
    mode: 'history',
    routes
});

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
