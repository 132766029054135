
import Vue from 'vue';
import bus from './event-bus.js';
import axios from 'axios';

const zasielkovna = new Vue({
    data: {
        zasielkovnaToken: null,
        zasielkovnaPassword: null,

        carriers: []
    },

    created: function() {
        console.log('Zasielkovna API init.');

        this.zasielkovnaToken = localStorage.zasielkovnaToken;
        this.zasielkovnaPassword = localStorage.zasielkovnaPassword;

        if (this.zasielkovnaToken && this.zasielkovnaPassword) {
            this.loadCarriers();
        }
    },

    methods: {
        configure(token, password) {
            this.zasielkovnaToken = token;
            this.zasielkovnaPassword = password;
            localStorage.zasielkovnaToken = token;
            localStorage.zasielkovnaPassword = password;
            this.getCarriers();
        },

        xmlToJson(xml) {
            var obj = {};
            if (xml.nodeType == 1) {
                if (xml.attributes.length > 0) {
                    obj["@attributes"] = {};
                    for (var j = 0; j < xml.attributes.length; j++) {
                        var attribute = xml.attributes.item(j);
                        obj["@attributes"][attribute.nodeName] = attribute.nodeValue;
                    }
                }
            } else if (xml.nodeType == 3) { // text
                obj = xml.nodeValue;
            }
            if (xml.hasChildNodes()) {
                for(var i = 0; i < xml.childNodes.length; i++) {
                    var item = xml.childNodes.item(i);
                    var nodeName = item.nodeName;
                    if (typeof(obj[nodeName]) == "undefined") {
                        obj[nodeName] = this.xmlToJson(item);
                    } else {
                        if (typeof(obj[nodeName].push) == "undefined") {
                            var old = obj[nodeName];
                            obj[nodeName] = [];
                            obj[nodeName].push(old);
                        }
                        obj[nodeName].push(this.xmlToJson(item));
                    }
                }
            }
            return obj;
        },

        parseXML(xml) {
            var parser = new DOMParser();
            var xmlDoc = parser.parseFromString(xml, 'text/xml');
            var json = this.xmlToJson(xmlDoc);
            return json
        },

        loadCarriers() {
            if (this.zasielkovnaToken === '') {
                console.error('Cannot load carriers, no token present...');
                return
            }
            console.log('Loading carriers...');
            axios.get(`https://www.zasilkovna.cz/api/v4/${this.zasielkovnaToken}/branch.xml`).then(response => {
                console.log('Loaded branches.');

                var json = this.parseXML(response.data);
                var carriers = json.export.carriers.carrier;
                console.log(`Found ${carriers.length} carriers...`);

                var list = [];
                for (var i = 0; i < carriers.length; i++) {
                    var carrier = {
                        id: carriers[i].id['#text'],
                        name: carriers[i].name['#text'],
                        country: carriers[i].country['#text'],
                        currency: carriers[i].currency['#text'],
                        labelName: carriers[i].labelName['#text'],
                        labelRouting: carriers[i].labelRouting['#text'],
                        pickupPoints: carriers[i].pickupPoints['#text'],
                        maxWeight: carriers[i].maxWeight['#text'],
                        apiAllowed: carriers[i].apiAllowed['#text'],
                        customsDeclarations: carriers[i].customsDeclarations['#text']
                    }
                    list.push(carrier);
                    console.log(carrier.name, `max. ${carrier.maxWeight}kg`);
                }

                this.carriers = list.sort((a, b) => a.country > b.country);
                bus.$emit('zasielkovna:carriers', list);

            }).catch(e => {
                console.log('Failed to load branches', e);
            });
        },

        getCarriers() {
            return this.carriers
        }

    }

});

export default zasielkovna;
