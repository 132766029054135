<template>
  <div id="app">
     <div>
        <b-navbar toggleable="lg" type="dark" variant="dark">
          <b-navbar-brand href="#"><strong class="mr-2">Magento Express</strong>Three D Store</b-navbar-brand>

          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
              <b-collapse id="nav-collapse" is-nav>
                  <b-navbar-nav>
                      <b-nav-item to="/orders">Orders</b-nav-item>
                      <b-nav-item to="/products">Products</b-nav-item>
                      <b-nav-item to="/stock">Stock</b-nav-item>
                      <b-nav-item to="/customers">Customers</b-nav-item>
                      <b-nav-item to="/shipments">Shipments</b-nav-item>
                      <b-nav-item to="/financial">Financial</b-nav-item>
                  </b-navbar-nav>
              </b-collapse>
          </b-navbar>
      </div>
      <router-view></router-view>
  </div>
</template>


<script>
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

export default {
    name: 'App',
    props: {
    }
}
</script>

<style>
#app {
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;

}
#orderTable {
    font-size: 11pt;
}
.modal-content {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 30px 0 rgba(0, 0, 0, 0.19);
}
.dropdown-menu {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 30px 0 rgba(0, 0, 0, 0.19);
}
.dropdown-item.active, .dropdown-item:active {
    background-color: #f0f0f0 !important;
    color: #3F3F3F !important;
}
.dropdown-item:hover {
    background-color: #17a2b8 !important;
    color: white !important;
}
.text-small {
    font-size: 9pt;
}
</style>
