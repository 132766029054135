<template>
    <div>
        <b-table id="orderTable" striped small hover responsive sort-icon-left :busy="isBusy" :items="items" :fields="fields" :tbody-tr-class="rowClass" ref="orderTable">
            <template v-slot:table-busy>
                <div class="text-center my-2">
                    <b-spinner size="sm" class="align-middle mr-2"></b-spinner>
                    <strong>Loading orders...</strong>
                </div>
            </template>
        </b-table>
    </div>
</template>

<script>
import bus from '../event-bus.js';
import magento from '../magento.js';

function formatPrice(value) {
    return value ? value.toFixed(2).replace('.', ',') : '0,00'
}

export default {
    name: 'Financial',
    props: {
    },

    components: {
    },

    mounted() {
        var that = this;
        bus.$on('magento:authenticated', function() {
            magento.getOrders();
        });
        bus.$on('magento:orders-loaded', function(data) {
            that.refreshTable(data);
        });

        bus.$on('magento:progress', function() {
            that.isBusy = true;
        });
        bus.$on('magento:done', function() {
            that.isBusy = false;
        });

        if (magento.isAuthenticated()) {
            magento.getOrders();
        } else {
            this.$refs.authenticate.authenticate();
        }

    },

    methods: {
        refreshTable(data) {
            this.items = data.map(item => ({
                created_at: item.created_at,
                state: item.state,
                status: item.status,

                customer_firstname: item.customer_firstname,
                customer_lastname: item.customer_lastname,
                customer_name: (item.customer_firstname ? item.customer_firstname : item.billing_address.firstname) + ' ' + (item.customer_lastname ? item.customer_lastname : item.billing_address.lastname),

                base_currency_code: item.base_currency_code,
                base_subtotal: item.base_subtotal,
                base_subtotal_incl_tax: item.base_subtotal_incl_tax,

                base_shipping_amount: item.base_shipping_amount,
                base_shipping_incl_tax: item.base_shipping_incl_tax,
                base_shipping_invoiced: item.base_shipping_invoiced,
                base_shipping_tax_amount: item.base_shipping_tax_amount,

                base_tax_amount: item.base_tax_amount,
                base_tax_invoiced: item.base_tax_invoiced,

                base_grand_total: item.base_grand_total,
                base_total_due: item.base_total_due,
                base_total_invoiced: item.base_total_invoiced,
                base_total_canceled: item.base_total_canceled,
                base_total_refunded: item.base_total_refunded,
                base_total_paid: item.base_total_paid,

                payment_method: this.mapPayment(item.payment.method, item.payment.additional_information[0]),
                base_to_order_rate: item.base_to_order_rate,
                order_currency_code: item.order_currency_code,

                country_id: item.billing_address.country_id ? item.billing_address.country_id : item.extension_attributes.shipping_assignments[0].shipping.address.country_id,
                vat_id: item.billing_address.vat_id,
                order_id_str: (item.payment.additional_information[0] === 'amazon' || item.payment.additional_information[0] === 'ebay') ? item.payment.additional_information[2] : item.increment_id
            }));
        },
        mapPayment(value, extra) {
            switch(value) {
            case 'paypal_express':
                return 'PayPal'
            case 'banktransfer':
                return 'SEPA'
            case 'stripe_payments':
                return 'Stripe'
            case 'm2epropayment':
                switch(extra) {
                case 'ebay':
                    return 'eBay'
                case 'amazon':
                    return 'Amazon'
                }
                return 'M2EPro'
            case 'cashondelivery':
                return 'Cash on Delivery';
            default:
                return value
            }
        },
        rowClass(item, type) {
            if (!item || type !== 'row') return
            switch(item.status) {
                case 'canceled':
                case 'closed':
                    return 'text-muted'
                case 'processing':
                case 'pending':
                    return 'text-info'
                default:
                    break
            }
            return null
        }
    },

    data() {
        return {
            isBusy: false,
            items: [],
            fields: [
                { key: 'created_at', label: 'Date', sortable: true, formatter: value => { return value.substring(0, 10) } },
                { key: 'customer_name', label: 'Customer', sortable: true},
                { key: 'order_id_str', label: 'Order', sortable: true },
                { key: 'state', label: 'State', sortable: true },
                { key: 'status', label: 'Status', sortable: true },
                { key: 'country_id', label: 'Cnt', sortable: true },
                { key: 'vat_id', label: 'VAT ID', sortable: true },
                { key: 'payment_method', label: 'Method', sortable: true },
                { key: 'base_currency_code', label: 'Base', sortable: true },
                { key: 'order_currency_code', label: 'Order', sortable: true },
                { key: 'base_to_order_rate', label: 'Rate', sortable: true, formatter: value => { return value.toString().replace('.', ',') } },
                { key: 'base_subtotal', label: 'Sub', sortable: true, tdClass: 'text-right', formatter: value => { return formatPrice(value) } },
                { key: 'base_subtotal_incl_tax', label: '+ Tax', sortable: true, tdClass: 'text-right', formatter: value => { return formatPrice(value) } },
                { key: 'base_shipping_amount', label: 'Ship', sortable: true, tdClass: 'text-right', formatter: value => { return formatPrice(value) } },
                { key: 'base_shipping_incl_tax', label: '+ Tax', sortable: true, tdClass: 'text-right', formatter: value => { return formatPrice(value) } },
                { key: 'base_grand_total', label: 'Total', sortable: true, tdClass: 'text-right', formatter: value => { return formatPrice(value) } },
                { key: 'base_tax_amount', label: 'Tax', sortable: true, tdClass: 'text-right', formatter: value => { return formatPrice(value) } },
                { key: 'base_total_invoiced', label: 'Invoice', sortable: true, tdClass: 'text-right', formatter: value => { return formatPrice(value) } },
                { key: 'base_total_canceled', label: 'Cancel', sortable: true, tdClass: 'text-right', formatter: value => { return formatPrice(value) } },
                { key: 'base_total_paid', label: 'Paid', sortable: true, tdClass: 'text-right', formatter: value => { return formatPrice(value) } },
                { key: 'base_total_refunded', label: 'Refund', sortable: true, tdClass: 'text-right', formatter: value => { return formatPrice(value) } },
                { key: 'base_total_due', label: 'Due', sortable: true, tdClass: 'text-right', formatter: value => { return formatPrice(value) } }
            ]
        }
    }
}
</script>

<style scoped>
</style>
