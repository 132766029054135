
import Vue from 'vue';

var busMixin = {
    data: {

    },
    methods: {

    }
}

const bus = new Vue({
    mixins: [busMixin],
    created: function() {
        console.log('Event bus created.');
    }
});

export default bus;
