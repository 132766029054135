<template>
    <div>
    <b-modal id="orderDialog" content-class="shadow" size="lg" v-bind:title="dialogTitle">

      <b-container fluid>
        <b-row align-v="center">
            <b-col class="text-muted"><strong>Amazon / eBay:</strong></b-col>
            <b-col cols="8">{{ order.amazon_order_id }}</b-col>
        </b-row>
        <b-row align-v="center">
            <b-col class="text-muted"><strong>Order date:</strong></b-col>
            <b-col cols="8"><strong>{{ order.created_at }}</strong></b-col>
        </b-row>
        <b-row align-v="center">
            <b-col class="text-muted"><strong>Status:</strong></b-col>
            <b-col cols="8"><b-badge variant="secondary">{{ order.status }}</b-badge></b-col>
        </b-row>
        <b-row align-v="center">
            <b-col class="text-muted"><strong>Customer:</strong></b-col>
            <b-col cols="8">{{ order.customer_name }}</b-col>
        </b-row>
        <b-row align-v="top">
            <b-col class="text-muted"><strong>Shipping address:</strong></b-col>
            <b-col cols="8" v-if="order">
                    {{ order.shipping_address.firstname }} {{ order.shipping_address.middlename }} {{ order.shipping_address.lastname }}
                    {{ order.shipping_address.street[0] }}
                    {{ order.shipping_address.street[1] }}
                    {{ order.shipping_address.postcode }}, {{ order.shipping_address.city }}
                    {{ order.shipping_address.region }}, {{ order.shipping_address.region_code }}
                    {{ order.shipping_address.country_id }}
                    {{ order.shipping_address.sk }}
                    {{ order.shipping_address.telephone }}
                    {{ order.email }}
            </b-col>
        </b-row>
        <b-row align-v="center">
            <b-col class="text-muted"><strong>Payment method:</strong></b-col>
            <b-col cols="8">{{ order.payment_method }}</b-col>
        </b-row>
        <b-row align-v="center">
            <b-col class="text-muted"><strong>Shipping method:</strong></b-col>
            <b-col cols="8">{{ order.shipping_description }}</b-col>
        </b-row>
        <b-row align-v="center">
            <b-col class="text-muted"><strong>Grand total:</strong></b-col>
            <b-col cols="8">{{ order.payment_amount }} {{ order.currency }}</b-col>
        </b-row>
        <b-row align-v="center">
            <b-col class="text-muted"><strong>Items:</strong></b-col>
            <b-col cols="8">{{ order.items_count }} pcs, {{ order.weight }} kg</b-col>
        </b-row>
    </b-container>

    <b-container fluid class="mt-3">
        <b-row v-for="prd in order.products" :key="prd.product_id" :class="{'header':prd.product_type!='simple'}" align-v="center">
            <b-col cols="2" class="header"><span v-if="prd.product_type=='simple'">O{{ prd.qty_ordered }}, B{{ prd.qty_backordered }}, R{{ prd.qty_refunded }}, I{{ prd.qty_invoiced }}, S{{ prd.qty_shipped }}</span></b-col>
            <b-col cols="4"><small>{{ prd.sku }}</small></b-col>
            <b-col cols="5">{{ prd.name }}</b-col>
            <b-col cols="1">{{ prd.price }}</b-col>
        </b-row>
    </b-container>

    <pre class="text-muted">{{ shipment }}</pre>

    <template v-slot:modal-footer>
        <div class="w-100">
            <b-button class="w-25 mr-2" size="md" variant="info" @click="openLabel">Shipping label</b-button>
            <b-button class="w-25 float-right" size="md" variant="outline-secondary" @click="$bvModal.hide('orderDialog')">Cancel</b-button>
        </div>
    </template>

    </b-modal>

    <ShippingLabel ref="labelDialog"/>
    </div>
</template>

<script>
//import axios from 'axios';
import ShippingLabel from './ShippingLabel.vue'

export default {
  name: 'Order',
  props: {
  },
  components: {
      ShippingLabel
  },

  methods: {
      openOrder(order) {
          console.log('Opening order', order);
          this.order = order;
          this.dialogTitle = order.order_id;

          this.order.productsSimple = this.order.products.filter(function(item) {
              return item.product_type === 'simple';
          });

          this.$bvModal.show('orderDialog');
      },
      openLabel() {
          this.$refs.labelDialog.openLabelFor(this.order);
      }
  },

  data() {
      return {
          show: false,
          order: {
              order_id: '',
              shipping_address: {
                  street: []
              }
          },
          xml: '',
          dialogTitle: '',
          shipment: ''
      }
  }
}

</script>

<style scoped>
    .header {
    color: #7f7f7f;
    font-size: 8pt;
    font-weight: bold;
}
</style>
