<template>
    <div>
        <b-modal id="modal-login" hide-footer>
            <template v-slot:modal-title>
                Login to Shipping Assistant
            </template>
            <div class="d-block">
                <b-form>
                    <h4 class="text-secondary">Magento 2 Admin:</h4>
                    <b-form-group label="Login:">
                        <b-form-input type="text" required autofocus placeholder="Enter your username" v-model="form.username"/>
                    </b-form-group>
                    <b-form-group label="Password:">
                        <b-form-input type="password" required placeholder="Enter password" v-model="form.password"/>
                    </b-form-group>
                    <h4 class="text-secondary mt-4">Zásielkovňa.sk API</h4>
                    <b-form-group label="API Key:">
                        <b-form-input type="text" required placeholder="Copy & paste your API key" v-model="form.zasielkovnaToken"/>
                    </b-form-group>
                    <b-form-group label="API Password:">
                        <b-form-input type="text" required placeholder="Copy & paste your API password" v-model="form.zasielkovnaPassword"/>
                    </b-form-group>
                </b-form>
            </div>
            <b-container fluid>
                <b-row align-h="end">
                    <b-button class="mt-3 mr-2 w-25" @click="$bvModal.hide('modal-login')">Cancel</b-button>
                    <b-button variant="dark" class="mt-3 w-25" @click="login">Logon</b-button>
                </b-row>
            </b-container>
        </b-modal>
    </div>
</template>

<script>
import bus from '../event-bus.js';
import magento from '../magento.js';
import zasielkovna from '../zasielkovna.js';

export default {
  name: 'Authenticate',
  props: {
  },
  data () {
      return {
          inProgress: false,
          form: {
              username: '',
              password: '',
              zasielkovnaToken: zasielkovna.zasielkovnaToken,
              zasielkovnaPassword : zasielkovna.zasielkovnaPassword,
          }
      }
  },

  mounted() {
      console.log('Authenticate component mounted.');
      var that = this;

      bus.$on('magento:authenticated', function() {
          console.log('Authenticated.');
          that.$bvModal.hide('modal-login');
      });
      bus.$on('magento:authfailed', function() {
          console.log('Authentication failed, reauthenticating...');
          that.$bvModal.show('modal-login');
      });
  },

  methods: {
      authenticate() {
          if (!magento.isAuthenticated()) {
              this.$bvModal.show('modal-login');
          }
      },

      login() {
          magento.authenticate(this.form.username, this.form.password);
          zasielkovna.configure(this.form.zasielkovnaToken, this.form.zasielkovnaPassword);
      }

  }
}
</script>

<style scoped>
</style>
