
import Vue from 'vue';
import bus from './event-bus.js';
import axios from 'axios';

const magento = new Vue({
    data: {
        magentoToken: null,
        magentoBackend: 'https://threed.store/rest/default/V1'
    },

    created: function() {
        console.log('Magento API initialized.');
        this.magentoToken = localStorage.magentoToken;
        if (this.isAuthenticated()) {
            bus.$emit('magento:authenticated');
        }
    },

    methods: {
        isAuthenticated() {
            return this.magentoToken != null
        },

        createHeaders() {
            var headers = {
                'Content-Type': 'application/json'
            }
            if (this.magentoToken) {
                headers['Authorization'] = `Bearer ${this.magentoToken}`;
            } else {
                console.error('No Magento token is being sent', this.magentoToken);
            }
            console.log('Headers', headers);
            return headers
        },

        authenticate(user, password) {
            console.log(`Authenticating user ${user}...`)
            bus.$emit('magento:progress');
            axios.post(`${this.magentoBackend}/integration/admin/token`, {
                username: user,
                password: password
            }).then(response => {
                this.magentoToken = response.data;
                localStorage.magentoToken = this.magentoToken;
                bus.$emit('magento:done');
                bus.$emit('magento:authenticated');
            }).catch(function(error) {
                console.log('Failed to authenticate', error);
                this.magentoToken = null;
                bus.$emit('magento:done');
                bus.$emit('magento:authfailed');
            });
        },

        getOrders() {
            console.log('Loading orders...');
            bus.$emit('magento:progress');
            axios.get(`${this.magentoBackend}/orders?searchCriteria=[0]`, {
                headers: this.createHeaders()
            }).then(response => {
                console.log('Received', response.data.items.length, 'orders');
                bus.$emit('magento:done');
                bus.$emit('magento:orders-loaded', response.data.items);
            }).catch(function(error) {
                console.log('Failed to load orders', error);
                bus.$emit('magento:done');
                bus.$emit('magento:authfailed');
                bus.$emit('orders-failed', error);
            });
        },

        shipOrder(orderId, payload) {
            console.log('Shipping order', orderId, payload);
            console.log(this.magentoToken, this.magentoBackend);
            const config = {
                method: 'POST',
                headers: this.createHeaders(),
                data: payload,
                url: `${this.magentoBackend}/order/${orderId}/ship`
            }
            axios(config).then(response => {
                console.log('Response', response);
            }).catch(function(error) {
                console.log('Error shipping order', error);
            });
        },

        getProductBySKU(sku, callback) {
            console.log('Retrieving product SKU', sku);
            const config = {
                method: 'GET',
                headers: this.createHeaders(),
                url: `${this.magentoBackend}/products/${sku}`
            }
            axios(config).then(response => {
                callback(response.data);
            }).catch(function(error) {
                console.log('Failed to retrieve product', sku, error);
                callback(null);
            });
        }

    }

});

export default magento;
